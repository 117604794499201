// import node module libraries
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, Col, Form, Modal, Row, Spinner, Tab } from "react-bootstrap";

// import custom components
import { addDoc, collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
// import bootstrap icons
// import JobSearchBox from "components/marketing/common/jobs/JobSearchBox";
// import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import { Offcanvas } from "react-bootstrap";
// import OffcanvasCreateProjectForm from "../projects/OffcanvasCreateProjectForm";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import Loader from "components/elements/miscellaneous/Loader";
import { DashboardContext } from "context/Context";
import { ToastContainer, toast } from "react-toastify";
import "../../../assets/scss/maps.scss";
import StatRightCenterIcon from "../common/stats/StatRightCenterIcon";
import RivalGridView from "./RivalGridView";
import RivalListView from "./RivalListView";
const RivalList = () => {
  // const http = require("https");
  const { user, company, setCompany } = useContext(DashboardContext);
  const db = getFirestore();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [grid, setGrid] = useState(true);
  const [address, setAddress] = useState("");
  const [crawlLink, setCrawlLink] = useState("");

  // const [place, setPlace] = useState(null);
  const [addressComponents, setAddressComponents] = useState(null);
  const autocompleteRef = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDBCTKGrx4ItlFjAjTGcG7ZG07_EU1kN1Q",
    libraries: ["places"],
  });

  const handleShow = () => {
    setName("");
    // setLoc("");
    setShow(true);
  };

  const [name, setName] = useState("");
  // const [loc, setLoc] = useState("");
  const [rivals, setRivals] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const [magnaPrices, setMagnaPrices] = useState([]);
  const [premiumPrices, setPremiumPrices] = useState([]);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    // setPlace(place);

    // Extract the address components from the place object
    const addressComponents = place.address_components.reduce((acc, component) => {
      acc[component.types[0]] = component.long_name;
      return acc;
    }, {});
    // AddressComponents:
    // country, locality (city), administrative_area_level_1 (state), postal_code,
    // street_number, route (street name), sublocality_level_1 (neighborhood)
    setAddressComponents(addressComponents);
    console.log(addressComponents);
    console.log("Ad: ", place.formatted_address);
    // Update the address state with the formatted address
    setAddress(place.formatted_address);
  };

  const checkIfValid = (value) => {
    if (
      value != null &&
      value !== "" &&
      value !== " " &&
      value !== undefined &&
      value.trim().length >= 1
      //    &&
      //   /^[a-zA-ZÁáÉéÍí.,#ÓóÚúÑñüÜ\s]+$/g.test(value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addRival = async () => {
    setLoading(true);

    if (checkIfValid(name) && checkIfValid(crawlLink)) {
      const rivalRef = await addDoc(collection(db, "companies", user.companyId, "rivals"), {
        name: name.trim(),
        // postal_code: addressComponents.postal_code,
        fullAddress: address ? address : "No disponible",
        crawlLink: crawlLink,
        // street_number: addressComponents.street_number,
        // country: addressComponents.country,
        // street: addressComponents.route,
        // city: addressComponents.locality,
        // state: addressComponents.administrative_area_level_1,
        // neighborhood: addressComponents.sublocality_level_1,
        dateCreated: new Date(),
        createdBy: user,
      });
      const updateRef = doc(db, "companies", user.companyId, "rivals", rivalRef.id);
      await setDoc(updateRef, { id: rivalRef.id }, { merge: true })
        .then(() => {
          setName("");
          setCrawlLink("");

          setShow(false);
          setLoading(false);
          toast.success("Rival agregado!");
          setChange((c) => !c);
        })
        .catch((er) => {
          alert(er);
          toast.error("Ha ocurrido un error");
          // setModal(false);
          setLoading(false);
        });
    } else {
      // setModal(false);
      setLoading(false);
      console.log(address);
      //   alert("Por favor llena todos los datos");
      toast.error("Por favor llena todos los datos");
    }
  };

  const validateInfo = () => {
    if (checkIfValid(name) && checkIfValid(crawlLink)) {
      // setModal(true);
      addRival();
    } else {
      // setModal(false);
      setLoading(false);
      toast.error("Por favor llena todos los datos");

      //   alert("Por favor llena todos los datos");
    }
  };

  const initial = async () => {
    setLoading(true);
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "rivals");
    const rivSnap = await getDocs(locsRef);
    let magnaPs = [];
    let premiumPs = [];
    rivSnap.forEach((e) => {
      resp.push(e.data());
      magnaPs.push(e.data().magnaPrice);
      premiumPs.push(e.data().premiumPrice);
    });
    if (resp.length >= 1) {
      setMagnaPrices(magnaPs);
      setPremiumPrices(premiumPs);
      setRivals(resp);
      setLoading(false);
    }
    setLoading(false);
  };
  //   useEffect(() => {}, [company]);
  useEffect(() => {
    initial();
  }, [change, company]);

  return (
    <Fragment>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        name="end"
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h3">Agregar Rival</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          {/* <OffcanvasCreateProjectForm onClick={handleClose} /> */}

          <Form>
            <Row>
              {/* Project's Name */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectTitle">
                  <Form.Label>
                    Alias <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    placeholder="Alias del rival"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>

              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectTitle">
                  <Form.Label>
                    Link <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={crawlLink}
                    placeholder="Link de la página del rival"
                    onChange={(e) => setCrawlLink(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>

              {/* Dirección */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectBrief">
                  <Form.Label>Dirección</Form.Label>
                  {isLoaded && (
                    <Autocomplete
                      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                      options={{ componentRestrictions: { country: "mx" } }}
                      onPlaceChanged={handlePlaceChanged}
                      className=""
                    >
                      <input
                        type="text"
                        className="places ps-3"
                        placeholder="Ingresa la dirección de tu negocio"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        required
                      />
                    </Autocomplete>
                  )}
                </Form.Group>
              </Col>

              {/* Buttons */}
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    validateInfo();
                    // checkCp();
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                  variant="outline-danger"
                  className="ms-2"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={modal} centered onHide={() => setModal(false)}>
        {loading ? (
          <Modal.Body className="py-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </Modal.Body>
        ) : (
          <div>
            <Modal.Header>
              <h2>Confirmar Creación</h2>
            </Modal.Header>

            <Modal.Body>
              <p className="fw-semi-bold">
                Estas a punto de crear una nueva sucursal, esto tendrá impacto en tu pago de
                mensualidad.
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  addRival();
                }}
              >
                Crear
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                variant="outline-danger"
                className="ms-2"
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
      <section className="py-8 bg-light">
        {/* <Row>
            <Col md={4} xl={3}>
              <JobFilters />
            </Col>
            <Col xl={9} md={8} className="mb-6 mb-md-0"> */}

        <Tab.Container defaultActiveKey="list">
          <Row className="row align-items-center justify-content-end mb-4">
            {/* <Col xs> */}
            <h2>🎯 Rivales</h2>
            {/* <GlobalFilter
                  filter={search}
                  setFilter={setSearch}
                  placeholder="Buscar por nombre"
                /> */}
            {/* </Col> */}
            <Col className="col-auto">
              <div className="d-flex ">
                {/* <GridListViewButton keyGrid="grid" keyList="list" /> */}
                <ButtonGroup className="me-3">
                  <Button
                    variant={grid ? "secondary" : "outline-secondary"}
                    onClick={(e) => {
                      e.preventDefault();
                      setGrid(true);
                    }}
                  >
                    <span className="fe fe-grid"></span>
                  </Button>
                  <Button
                    variant={grid ? "outline-secondary" : "secondary"}
                    onClick={(e) => {
                      e.preventDefault();
                      setGrid(false);
                    }}
                  >
                    <span className="fe fe-list"></span>
                  </Button>
                </ButtonGroup>
                {/* <FormSelect options={sortByOptions} placeholder="Sorting" /> */}

                {user.permission >= 4 && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleShow();
                    }}
                  >
                    + Agregar Rival
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Card>
            <Card.Header className="card-header">
              <h4 className="mb-0">Magna </h4>
            </Card.Header>
            <Row>
              <Col lg={4} md={12} xs={12}>
                <StatRightCenterIcon
                  title="Mínimo"
                  value={"$" + Math.min(...magnaPrices)}
                  iconName="minus"
                  iconColorVariant="danger"
                />
              </Col>
              <Col lg={4} md={12} xs={12} className="border-start-md">
                <StatRightCenterIcon
                  title="Máximo"
                  value={"$" + Math.max(...magnaPrices)}
                  iconName="plus"
                  iconColorVariant="success"
                />
              </Col>
              <Col lg={4} md={12} xs={12} className="border-start-md">
                <StatRightCenterIcon
                  title="Promedio"
                  value={
                    "$" + (magnaPrices.reduce((a, b) => a + b, 0) / magnaPrices.length).toFixed(2)
                  }
                  iconName="bar-chart"
                  iconColorVariant="primary"
                />
              </Col>
            </Row>
          </Card>
          <br />
          <Card className="mb-4">
            <Card.Header className="card-header">
              <h4 className="mb-0">Premium </h4>
            </Card.Header>
            <Row>
              <Col lg={4} md={12} xs={12}>
                <StatRightCenterIcon
                  title="Mínimo"
                  value={"$" + Math.min(...premiumPrices)}
                  iconName="minus"
                  iconColorVariant="danger"
                />
              </Col>
              <Col lg={4} md={12} xs={12} className="border-start-md">
                <StatRightCenterIcon
                  title="Máximo"
                  value={"$" + Math.max(...premiumPrices)}
                  iconName="plus"
                  iconColorVariant="success"
                />
              </Col>
              <Col lg={4} md={12} xs={12} className="border-start-md">
                <StatRightCenterIcon
                  title="Promedio"
                  value={
                    "$" +
                    (premiumPrices.reduce((a, b) => a + b, 0) / premiumPrices.length).toFixed(2)
                  }
                  iconName="bar-chart"
                  iconColorVariant="primary"
                />
              </Col>
            </Row>
          </Card>

          {rivals.length >= 1 && !loading ? (
            <div>
              {grid ? (
                <div>{loading ? <Loader /> : <RivalGridView data={rivals} />}</div>
              ) : (
                <div>{loading ? <Loader /> : <RivalListView data={rivals} />}</div>
              )}
            </div>
          ) : (
            <div>
              {loading ? (
                <div
                  className="h-100 w-100 d-flex align-items-center justify-content-center"
                  style={{ minHeight: "10rem" }}
                >
                  {" "}
                  <Spinner animation="border" variant="primary" className="me-2" />
                </div>
              ) : (
                <p>No tienes ubicaciones por el momento</p>
              )}
            </div>
          )}
        </Tab.Container>

        {/* </Col> */}
        {/* </Row> */}
      </section>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default RivalList;
